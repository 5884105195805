<template >
  <div class="containter">
    <div class="headImgArea">
      <!-- <img :src="detail.websiteBanner" style="width:100%;height:100%" /> -->
      <van-image fit="contain" :src="detail.websiteBanner" style="width:100%;height:100%">
        <template v-slot:error>
          <img :src="errorImg" style="width:100%;height:100%;" />
        </template>
      </van-image>
    </div>
    <div class="tabMsgArea">
      <div class="mainTitleLine">
        <span class="marginLeft10">{{detail.activityName}}</span>
      </div>
      <div class="mainBottomLine">
        <div class="leftMainArea">
          <div class="leftMainAreaFont2">报名人数：</div>
          <span class="leftMainAreaFontNumNow">{{detail.enrollCount}}</span>
          <span class="leftMainAreaFontNum">/</span>
          <span class="leftMainAreaFontNum">{{detail.activityNum}}</span>
        </div>
        <div class="rightMainArea">
          <img :src="greyLove" class="leftMainAreaImg" />
          <div class="leftMainAreaFont">{{detail.praiseCount}}</div>
          <img :src="eyeIcon" class="leftMainEyeImg" />
          <div
            class="leftMainAreaFont"
          >{{detail.readCount&&detail.readCount!==-1?detail.readCount:0}}</div>
        </div>
      </div>
      <div class="baseLine"></div>
      <div class="msgTab">
        <div class="msgTabLine">
          <span class="marginLeft10">活动类型：{{detail.activityTypeName}}</span>
        </div>
        <div class="msgTabLine">
          <span class="marginLeft10">主办单位：{{detail.deptName}}</span>
        </div>
        <div class="msgTabLine">
          <span class="marginLeft10">活动地点：{{detail.activitySite}}</span>
        </div>
        <div class="msgTabLine">
          <span class="marginLeft10">活动时间：{{detail.activityStartTime+'至'+detail.activityFinishTime}}</span>
        </div>
        <div class="msgTabLine">
          <span class="marginLeft10">发布人：{{detail.createUserName}}</span>
        </div>
      </div>
      <div class="height10"></div>
    </div>
    <div class="departArea">
      <div class="avtorArea">
        <!-- <img :src="detail.img" style="width:100%;height:100%;" /> -->
        <van-image fit="cover" :src="detail.img" style="width:100%;height:100%;">
          <template v-slot:error>
            <img :src="errorPicAvatar" style="width:100%;height:100%;" />
          </template>
        </van-image>
      </div>
      <div class="listItemTitleLineContent">
        <div class="listItemTitleLineContent1">{{ detail.deptName}}</div>
        <div class="listItemTitleLineContent2">{{ detail.activityStartTime}}</div>
      </div>
    </div>
    <div class="tabMsgArea">
      <div class="mainTitleLine">
        <span class="marginLeft10">活动详情</span>
      </div>
      <div class="rich-text" v-html="detail.activityContent"></div>
    </div>
    <div class="tabMsgArea marginBottom60">
      <div class="height10"></div>
      <div class="commentsTitle">评论（{{commentsTotal}}）</div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getComments">
          <div v-for="item in commentsList" class="commentsItem" :key="item.id">
            <div class="commentsAvtorArea">
              <div class="commentsAvtor">
                <!-- <img :src="item.avatar" style="width:100%;height:100%" /> -->
                <van-image fit="cover" :src="item.avatar" style="width:100%;height:100%">
                  <template v-slot:error>
                    <img :src="errorPicAvatar" style="width:100%;height:100%;" />
                  </template>
                </van-image>
              </div>
            </div>
            <div class="commentsContentArea">
              <div class="commetUserAndDate">
                <div class="fontName">{{item.realName}}</div>
                <div class="fontDate">{{item.updateTime}}</div>
              </div>
              <div class="fontContent">{{item.reviewContent}}</div>
              <div class="commentsImgLine" v-if="item.reviewImages&&item.reviewImages.length>0">
                <div
                  class="commentsImgItem"
                  v-for="imgItem in item.reviewImages.split(',')"
                  :key="imgItem"
                >
                  <!-- <img :src="imgItem" style="width:100%;height:100%" /> -->
                  <van-image fit="cover" :src="imgItem" style="width:100%;height:100%">
                    <template v-slot:error>
                      <img :src="errorPicAvatar" style="width:100%;height:100%;" />
                    </template>
                  </van-image>
                </div>
              </div>
              <div class="height15"></div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>

      <div class="height10"></div>
    </div>
    <div class="bottomZanLine">
      <div class="zanColumn">
        <img v-if="detail.praiseStatus" :src="bigRedHeart" class="imgRedHeart" />
        <img v-else :src="bigGreyHeart" class="imgRedHeart" @click="getPraisePost" />
        <div class="zanfont">赞</div>
      </div>
      <div class="zanColumn" @click="gotoComment">
        <img :src="comments" class="imgComments" />
        <div class="zanfont">评论</div>
      </div>
      <div class="bottomButtonArea">
        <div
          v-if="detail.enrollStatus===0&&detail.enrollCount!==detail.activityNum&&selectRow===0"
          class="bottomButton baomingButton"
          @click="getBaomingPost"
        >立即报名</div>
        <div
          v-if="detail.enrollStatus===1&&selectRow===0"
          class="bottomButton haveBaomingButton"
        >已报名</div>
        <div
          v-if="detail.enrollStatus===0&&detail.enrollCount===detail.activityNum&&selectRow===0"
          class="bottomButton haveBaomingButton"
        >人数已满</div>
        <div v-if="selectRow===1" class="bottomButton">已结束</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import {
  activityDetail,
  readMountPlus,
  praisePost,
  baomingPost,
  commentsList
} from '../../api/activity';
import * as dd from 'dingtalk-jsapi';

export default {
  name: 'ActivityDetail',

  data() {
    return {
      greyLove: require('./imgs/greyHeart.png'),
      eyeIcon: require('./imgs/eyeIcon.png'),
      bigRedHeart: require('./imgs/bigRedHeart.png'),
      bigGreyHeart: require('./imgs/bigGreyHeart.png'),
      comments: require('./imgs/comments.png'),
      errorImg: require('../../assets/img/list.png'),
      errorPicAvatar: require('../../assets/img/list-compre.png'),
      param: {
        page: 0,
        size: 5,
        total: 20
      },
      loading: false,
      finished: false,
      refreshing: false,
      detail: {},
      commentsList: [],
      selectRow: 0,
      commentsTotal: 0
    };
  },
  watch: {},
  created() {
    dd.biz.navigation.setTitle({
      title: '活动详情', //控制标题文本，空字符串表示显示默认文本
      onSuccess: function(result) {
        /*结构
        {
        }*/
      },
      onFail: function(err) {}
    });
    this.getRecords();
    // 阅读数加一
    this.readCountPlus();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const this_ = this;
      const { id, selectRow } = this.$route.query;
      console.log(selectRow);
      this.selectRow = Number(selectRow);
      // 利用id查询detail
      activityDetail({
        id: id
      })
        .then(res => {
          if (res.success) {
            const detail = res.data || {};
            detail.activityStartTime = detail.activityStartTime
              ? detail.activityStartTime.slice(0, 16)
              : '';
            detail.activityFinishTime = detail.activityFinishTime
              ? detail.activityFinishTime.slice(0, 16)
              : '';
            this_.detail = detail;
            console.log(selectRow, this_.detail.enrollStatus);
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    readCountPlus() {
      const this_ = this;
      const { id } = this.$route.query;
      readMountPlus({
        id: id
      })
        .then(res => {
          if (res.success) {
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取评论列表
    getComments() {
      const this_ = this;
      const { id } = this.$route.query;
      const param = this.param;
      param.page += 1;
      // 调接口
      commentsList({
        current: param.page,
        size: 5,
        objectId: id,
        praiseType: 'ACTIVITY_TYPE'
      })
        .then(res => {
          if (res.success) {
            const activityList = res.data.records;
            this_.commentsTotal = res.data.total;
            let list = this_.commentsList;
            list = list.concat(activityList);
            this_.commentsList = JSON.parse(JSON.stringify(list));
            console.log(this_.commentsList);
            param.total = res.data.total;
            this_.param = param;
            // 加载状态结束
            this_.loading = false;
            this_.refreshing = false;
            // 数据全部加载完成
            if (this_.commentsList.length >= param.total) {
              this_.finished = true;
            }
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getComments();
    },
    // 点赞
    getPraisePost() {
      const detail = this.detail;
      praisePost({
        addPraise: true,
        id: detail.id,
        type: 'ACTIVITY_TYPE'
      })
        .then(res => {
          if (res.success) {
            detail.praiseStatus = true;
            detail.praiseCount += 1;
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 报名
    getBaomingPost() {
      const item = this.detail;
      baomingPost({
        enrolled: true,
        id: item.id
      })
        .then(res => {
          if (res.success) {
            item.enrollStatus = 1;
            item.enrollCount += 1;
            Notify({ type: 'success', message: '报名成功' });
          } else {
            Notify({ type: 'warning', message: res.msg });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    gotoComment() {
      const { id } = this.detail;
      this.$router.push({ name: 'activityComment', query: { id: id } });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.headImgArea {
  width: 100%;
  height: 160 * $px;
  overflow: hidden;
}
.mainTitleLine {
  width: 100%;
  height: 46 * $px;
  line-height: 46 * $px;
  color: #222222;
  font-size: 16 * $px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.marginLeft10 {
  margin-left: 10 * $px;
}
.mainBottomLine {
  width: 100%;
  height: 44 * $px;
}
.leftMainArea {
  width: 30%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rightMainArea {
  width: 70%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.leftMainAreaImg {
  width: 16 * $px;
  height: 14.5 * $px;
  margin-left: 12 * $px;
}
.leftMainAreaFont {
  width: 30 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 5 * $px;
}
.leftMainAreaFont2 {
  width: 62 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 10 * $px;
}
.leftMainEyeImg {
  width: 16 * $px;
  height: 12 * $px;
}
.leftMainAreaFontNum {
  font-size: 12 * $px;
  color: #555555;
}
.leftMainAreaFontNumNow {
  color: #2797ff;
  font-size: 16 * $px;
}
.msgTab {
  width: 100%;
  height: 130 * $px;
}
.baseLine {
  width: 351 * $px;
  height: 0.5 * $px;
  margin-left: 12 * $px;
  background-color: #eeeeee;
}
.msgTabLine {
  width: 100%;
  height: 20%;
  font-size: 14 * $px;
  color: #555555;
  display: flex;
  align-items: center;
}
.tabMsgArea {
  background-color: #ffffff;
}
.height10 {
  width: 100%;
  height: 10 * $px;
}
.height15 {
  width: 100%;
  height: 15 * $px;
}
.departArea {
  width: 100%;
  height: 60 * $px;
  margin: 10 * $px 0;
  background-color: #ffffff;
}
.avtorArea {
  width: 40 * $px;
  height: 40 * $px;
  border-radius: 20 * $px;
  overflow: hidden;
  background-color: grey;
  margin: 10 * $px;
  float: left;
}
.listItemTitleLineContent {
  width: 280 * $px;
  height: 100%;
  float: left;
}
.listItemTitleLineContent1 {
  font-size: 14 * $px;
  color: #555555;
  margin: 12 * $px 10 * $px 0 * $px 10 * $px;
}
.listItemTitleLineContent2 {
  font-size: 11 * $px;
  color: #999999;
  margin-left: 10 * $px;
}
.rich-text {
  padding: 10 * $px;
  margin-bottom: 10 * $px;
  font-size: 14 * $px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}
.commentsTitle {
  font-size: 14 * $px;
  color: #222222;
  margin-bottom: 5 * $px;
  margin-left: 12.5 * $px;
}
.commentsItem {
  margin: 15 * $px 12 * $px 0 12 * $px;
  overflow: auto;
  border-bottom: #eeeeee 0.5 * $px solid;
}
.commentsAvtorArea {
  width: 33 * $px;
  float: left;
  display: flex;
  justify-content: center;
}
.commentsAvtor {
  width: 33 * $px;
  height: 33 * $px;
  background-color: grey;
  overflow: hidden;
  border-radius: 16.5 * $px;
}
.commentsContentArea {
  margin-left: 8 * $px;
  width: 310 * $px;
  float: left;
}
.commetUserAndDate {
  height: 15 * $px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontName {
  color: #555555;
  font-size: 14 * $px;
}
.fontDate {
  color: #999999;
  font-size: 11 * $px;
}
.fontContent {
  margin: 8 * $px 0 0 0;
  color: #222222;
  font-size: 14 * $px;
}
.commentsBottomLine {
  width: 100%;
  height: 0.5 * $px;
  background-color: #eeeeee;
  margin-top: 15 * $px;
}
.commentsImgLine {
  width: 100%;
  word-break: break-all;
  overflow: auto;
}
.commentsImgItem {
  height: 70 * $px;
  width: 70 * $px;
  margin-top: 9 * $px;
  margin-right: 10 * $px;
  background-color: #dddddd;
  overflow: hidden;
  float: left;
}
.marginBottom60 {
  margin-bottom: 60 * $px;
}
.bottomZanLine {
  position: fixed;
  bottom: 0 * $px;
  left: 0 * $px;
  width: 100%;
  height: 50 * $px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}
.zanColumn {
  width: 26 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20 * $px;
}
.imgRedHeart {
  width: 22 * $px;
  height: 19 * $px;
  margin-top: 10 * $px;
}
.imgComments {
  width: 19 * $px;
  height: 19 * $px;
  margin-top: 10 * $px;
}
.zanfont {
  margin-top: 2 * $px;
  font-size: 12 * $px;
  color: #555555;
}
.bottomButtonArea {
  width: 230 * $px;
  height: 100%;
  margin-left: 34 * $px;
  display: flex;
  align-items: center;
}
.bottomButton {
  width: 230 * $px;
  height: 33 * $px;
  border-radius: 16.5 * $px;
  text-align: center;
  line-height: 33 * $px;
  font-size: 14 * $px;
}
.baomingButton {
  background-color: #ff192f;
  color: #ffffff;
}
.haveBaomingButton {
  border: #ff192f 1 * $px solid;
  box-sizing: border-box;
  color: #ff192f;
}
.activityEnd {
  background-color: #eeeeee;
  color: #999999;
}
</style>



